import React from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import ProductThemeProvider from "@civicplus/preamble-ui/lib/ProductThemeProvider";
import loadAsyncStore from "./app/store";
import EmbedErrorBoundary from "./components/ErrorHandler/EmbedErrorBoundary";
import ErrorBoundary from "./components/ErrorHandler/ErrorBoundary";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import SilentRenew from "./components/Common/SilentRenew";
import loadable from "@loadable/component";

const LoadableEventListWrapper = loadable(
  () => import("./components/EventsTable/EventListWrapper"),
  {
    fallback: <Loader />,
  }
);

const LoadableEventList = loadable(
  () => import("./components/EventsTable/EventList"),
  {
    fallback: <Loader />,
  }
);

const LoadableLoginCallback = loadable(
  () => import("./components/Common/LoginCallback"),
  {
    fallback: <Loader />,
  }
);

const LoadableLogoutCallback = loadable(
  () => import("./components/Common/LogoutCallback"),
  {
    fallback: <Loader />,
  }
);

const LoadableEventDetails = loadable(
  () => import("./components/EventDetails/EventDetails"),
  {
    fallback: <Loader />,
  }
);

const LoadableSearchTable = loadable(
  () => import("./components/Search/SearchTable"),
  {
    fallback: <Loader />,
  }
);

const LoadablePage404 = loadable(
  () => import("./components/ErrorHandler/Page404"),
  {
    fallback: <Loader />,
  }
);

const LoadableManageSubscription = loadable(
  () => import("./components/Common/ManageSubscription"),
  {
    fallback: <Loader />,
  }
);

const LoadableEventFilter = loadable(
  () => import("./components/EventsTable/EventFilter"),
  {
    fallback: <Loader />,
  }
);

const LoadableEventSidebar = loadable(
  () => import("./components/EventsTable/EventSidebar"),
  {
    fallback: <Loader />,
  }
);

const LoadablePortalHeader = loadable(
  () => import("./components/Common/PortalHeader"),
  {
    fallback: <Loader />,
  }
);

if (process.env.REACT_APP_EMBED) {
  // Render the embedded stuff
  const initialize = async () => {
    const store = await loadAsyncStore();
    if (store === null) throw new Error("Unable to load store.");

    const eventListingElement = document.getElementById("clerk-embed-listing");
    eventListingElement?.setAttribute(
      "style",
      "height: 800px; max-height: 100vh; display: flex;"
    );
    const embedViewElement = document.getElementById("clerk-embed-simple");
    embedViewElement?.setAttribute(
      "style",
      "height: 1000px; max-height: 100vh; display: flex; "
    );
    if (embedViewElement) {
      ReactDOM.render(
        <React.StrictMode>
          <ErrorBoundary>
            <Provider store={store}>
              <App prefix="clerk-simple" store={store}>
                <>
                  <LoadableManageSubscription />
                  <LoadableEventListWrapper />
                </>
              </App>
            </Provider>
          </ErrorBoundary>
        </React.StrictMode>,
        embedViewElement
      );
    } else if (eventListingElement) {
      ReactDOM.render(
        <React.StrictMode>
          <EmbedErrorBoundary>
            <Provider store={store}>
              <App prefix="clerk-listing" store={store}>
                <LoadableEventList />
              </App>
            </Provider>
          </EmbedErrorBoundary>
        </React.StrictMode>,
        eventListingElement
      );
    }

    const embedSidebarElement = document.getElementById("clerk-embed-sidebar");
    if (embedSidebarElement) {
      ReactDOM.render(
        <React.StrictMode>
          <EmbedErrorBoundary>
            <Provider store={store}>
              <App prefix="clerk-sidebar" store={store}>
                <LoadableEventSidebar />
              </App>
            </Provider>
          </EmbedErrorBoundary>
        </React.StrictMode>,
        embedSidebarElement
      );
    }

    const embedFilterElement = document.getElementById("clerk-embed-filter");
    if (embedFilterElement) {
      ReactDOM.render(
        <React.StrictMode>
          <EmbedErrorBoundary>
            <Provider store={store}>
              <App prefix="clerk-filter" store={store}>
                <LoadableEventFilter />
              </App>
            </Provider>
          </EmbedErrorBoundary>
        </React.StrictMode>,
        embedFilterElement
      );
    }

    const embedSubscriptionElement = document.getElementById(
      "clerk-embed-subscription"
    );
    if (embedSubscriptionElement) {
      ReactDOM.render(
        <React.StrictMode>
          <ErrorBoundary>
            <Provider store={store}>
              <App prefix="clerk-subscription" store={store}>
                <LoadableManageSubscription />
              </App>
            </Provider>
          </ErrorBoundary>
        </React.StrictMode>,
        embedSubscriptionElement
      );
    }
  };
  initialize();
} else {
  // Render full portal
  const rootElement = document.getElementById("root");

  ReactDOM.render(
    <ProductThemeProvider primaryHex="#b3b3b3">
      <Loader aria-label="Loading page content" verticallyCenter={true} />
    </ProductThemeProvider>,
    rootElement
  );

  const initialize = async () => {
    const store = await loadAsyncStore();
    if (store === null) throw new Error("Unable to load store.");

    ReactDOM.render(
      <React.StrictMode>
        <ErrorBoundary>
          <Provider store={store}>
            <App store={store}>
              <BrowserRouter>
                <LoadablePortalHeader />
                <ErrorBoundary>
                  <Switch>
                    <Route path="/" exact={true}>
                      <LoadableEventListWrapper />
                    </Route>
                    <Route path="/login-callback">
                      <LoadableLoginCallback />
                    </Route>
                    <Route path="/logout-callback">
                      <LoadableLogoutCallback />
                    </Route>
                    <Route path="/silent-renew">
                      <SilentRenew />
                    </Route>
                    <Route path="/event/:eventId">
                      <LoadableEventDetails />
                    </Route>
                    <Route path="/search">
                      <LoadableSearchTable />
                    </Route>
                    <Route exact={true} path="/(subscriptions)?/">
                      <LoadableEventListWrapper />
                    </Route>
                    <Route>
                      <LoadablePage404 />
                    </Route>
                  </Switch>
                </ErrorBoundary>
              </BrowserRouter>
            </App>
          </Provider>
        </ErrorBoundary>
      </React.StrictMode>,
      rootElement
    );
  };
  initialize();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
