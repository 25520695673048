import { rowQuerySelector } from "../components/EventsTable/EventListRow";
import { scroller } from "react-scroll";

export const getQueryParamByName = (
  urlQuery: string,
  paramName: string
): string => {
  const params = new URLSearchParams(urlQuery);
  return params.get(paramName) || "";
};

export const removeStringDateTimeZone = (date: string): string => {
  if (date) {
    return date.replace("Z", "");
  }
  return "";
};

export const getEventDetailTabLink = (
  eventId: number | string,
  tab: string
): string => {
  return `${window.location.protocol}//${window.location.host}/event/${eventId}/${tab}`;
};

export const closestEvent = (
  date: Date
): { closestElement: Element | undefined; onRange: boolean } => {
  const events = document.querySelectorAll(rowQuerySelector);
  let onRange = true;
  let closestElement: Element | undefined = events[0];
  let startDate = new Date();
  let endDate = new Date();
  let firstData = true;
  for (const event of events) {
    const attribute = event.getAttribute("data-date");
    if (attribute) {
      const thisDate = convertUtcDateToLocalDateWithoutTime(attribute);
      thisDate.setHours(0, 0, 0);
      if (firstData) {
        firstData = false;
        startDate = endDate = thisDate;
      } else {
        startDate = thisDate <= startDate ? thisDate : startDate;
        endDate = thisDate > endDate ? thisDate : endDate;
      }

      if (thisDate.getTime() === date.getTime()) {
        closestElement = event;
        break;
      }
    }
  }

  if (closestElement) {
    const dateEle = closestElement.getAttribute("data-date");
    if (dateEle) {
      const thisDate = new Date(dateEle);
      thisDate.setHours(0, 0, 0);
      startDate = thisDate;
    }
  }

  date.setHours(0, 0, 0, 0);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  if (date <= startDate || date >= endDate) {
    onRange = false;
  }
  return { closestElement, onRange };
};

export const handleTodayClick = (): void => {
  const today = new Date();
  today.setHours(0, 0, 0);
  const { onRange } = closestEvent(today);
  // If the today date is on the range of what we have currently on screen loaded,
  // then we scroll to the closest one
  if (onRange) {
    scrollToDate(today);
  } else {
    throw new Error("Today Not Loaded");
  }
};

export const scrollToDate = (date: Date): Date => {
  let returnDate = date;
  const { closestElement } = closestEvent(date);
  if (closestElement) {
    const dateString = closestElement.getAttribute("data-date");
    if (dateString) {
      returnDate = new Date(dateString);
    }
    scroller.scrollTo(closestElement.id, {
      duration: 500,
      containerId: "scroll-wrap",
      smooth: true,
      offset: -50, // offset for the sticky header
    });
  }
  return returnDate;
};

export const convertUtcDateToLocalDateWithoutTime = (date: string): Date => {
  const dateWithoutTimezone = new Date(date);
  // Ensure that the time is not offset when converting that string to a Date object: https://stackoverflow.com/a/52352512
  // (Convert to the same time in the current timezone -- undo offset)
  return new Date(
    dateWithoutTimezone.valueOf() +
      dateWithoutTimezone.getTimezoneOffset() * 60 * 1000
  );
};

export const makeFullUrl = (path: string): string => {
  const envToAdd =
    process?.env?.REACT_APP_ENV === "qa" ||
    process?.env?.REACT_APP_ENV === "staging"
      ? `.${process?.env?.REACT_APP_ENV}`
      : "";

  const tenant = window.clerkEmbedProps?.tenant
    ? window.clerkEmbedProps?.tenant
    : window.location.hostname.split(".")[0];

  return `https://${tenant}${envToAdd}.portal.civicclerk.com${path}`;
};
