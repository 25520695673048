import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { UserManager } from "oidc-client";
import UserManagerCP from "./utils/UserManagerCP";
import {
  Breakpoints,
  makeStyles,
  PreambleTheme,
} from "@civicplus/preamble-ui/lib/Utilities/ThemeHelper";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import ProductThemeProvider from "@civicplus/preamble-ui/lib/ProductThemeProvider";
import { EnhancedStore } from "@reduxjs/toolkit";
import { getPortalCustomizationAsync } from "./actions";
import Loader from "@civicplus/preamble-ui/lib/Loader";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./app/appInsights";
import { AppDispatch, RootState } from "./app/store";

declare global {
  interface Window {
    clerkEmbedProps?: {
      tenant?: string;
      color?: string;
      // Set as CSV to match the query string implementation.
      categoryIds?: string;
      breakpoints?: PreambleTheme["breakpoints"]["values"];
      maxBreakpoint?: Breakpoint;
    };
  }
}

interface AppProps {
  store: EnhancedStore<RootState>;
  children: JSX.Element;
  // This is required when multiple React trees are rendered to prevent CSS conflicts
  prefix?: string;
}

export const snackbarContainerId = "clerk-snackbar-container";

const useStyles = makeStyles(theme => ({
  app: {
    height: "100%",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.background.default,
    //This keeps safe spaces for mobile. Helps eliminate overflow in safe areas
    padding:
      "0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
  },
  "@global": {
    html: process.env.REACT_APP_EMBED ? undefined : { height: "100%" },
    "#root": process.env.REACT_APP_EMBED
      ? undefined
      : {
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: "1 1 auto",
        },
    'body > iframe[src*="connect/authorize"]': {
      display: "none",
    },
    body: process.env.REACT_APP_EMBED
      ? undefined
      : {
          display: "flex",
          height: "100%",
          margin: 0,
        },
  },
}));

const App: React.VFC<AppProps> = ({ store, children, prefix }) => {
  const portalCustomization = useSelector(
    (state: RootState) => state.portalCustomization
  );
  const dispatch = useDispatch<AppDispatch>();
  const maxBreakpoint = window.clerkEmbedProps?.maxBreakpoint;
  const breakpointOverride = window.clerkEmbedProps?.breakpoints;
  const themeOverride = {
    breakpoints: {
      values: breakpointOverride,
    } as Breakpoints,
  };

  const classes = useStyles();
  useEffect(() => {
    if (portalCustomization == null) {
      getPortalCustomizationAsync()(dispatch);
    }
  }, [dispatch, portalCustomization]);

  const [snackbarContainer, setSnackbarContainer] = useState<
    HTMLDivElement | undefined
  >(undefined);

  // Workaround to allow Snackbars to be visible from a Dialog
  useEffect(() => {
    const snackbarContainer = document.createElement("div");
    snackbarContainer.id = snackbarContainerId;

    document.body.appendChild(snackbarContainer);
    setSnackbarContainer(snackbarContainer);
    return () => {
      document.body.removeChild(snackbarContainer);
    };
  }, []);

  if (!portalCustomization) {
    return (
      <div
        id="app"
        className={classes.app}
        data-testid="loading"
        aria-describedby="loader-app"
      >
        <ProductThemeProvider
          primaryHex="#b3b3b3"
          cssReset={Boolean(process.env.REACT_APP_EMBED)}
          specificity={process.env.REACT_APP_EMBED ? 3 : undefined}
          prefix={prefix}
        >
          <Loader
            data-testid="loader"
            aria-label="Loading page content"
            id="loader-app"
            verticallyCenter={true}
          />
        </ProductThemeProvider>
      </div>
    );
  }
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <div id="app" className={classes.app} data-testid="root">
        <OidcProvider
          store={store}
          userManager={UserManagerCP.userManager as UserManager}
        >
          <ProductThemeProvider
            product="clerk"
            primaryHex={
              window.clerkEmbedProps?.color ||
              portalCustomization?.publicPortalCustomColor ||
              "#b3b3b3"
            }
            cssReset={Boolean(process.env.REACT_APP_EMBED)}
            specificity={process.env.REACT_APP_EMBED ? 3 : undefined}
            prefix={prefix}
            themeOverride={breakpointOverride ? themeOverride : undefined}
            maxBreakpoint={maxBreakpoint ? maxBreakpoint : undefined}
            SnackbarProviderProps={{
              domRoot: snackbarContainer,
            }}
          >
            {children}
          </ProductThemeProvider>
        </OidcProvider>
      </div>
    </AppInsightsContext.Provider>
  );
};

export default App;
