import { EventFilters } from "../types/types.event";
import { getQueryParamByName } from "../utils";
import { SearchSubFilter } from "../types/types.search";

const getCategoriesFromURL = () => {
  const categories: number[] = [];
  let catIds;
  if (process.env.REACT_APP_EMBED) {
    catIds = window.clerkEmbedProps?.categoryIds || undefined;
  } else {
    catIds = getQueryParamByName(window.location.search, "category_id");
  }
  if (catIds) {
    catIds.split(",").forEach(categoryId => {
      const id = Number.parseInt(categoryId);
      if (!isNaN(id)) {
        categories.push(id);
      }
    });
  }
  return categories;
};

const getContentTypesFromURL = () => {
  let contentTypes: string[] = [];
  const filters = getQueryParamByName(window.location.search, "filters");
  if (filters) {
    const currentFiltersArr = filters.split(",");
    contentTypes = currentFiltersArr.filter(value =>
      Object.values(SearchSubFilter).includes(value as SearchSubFilter)
    );
  }
  return contentTypes;
};

const eventFilterModel: EventFilters = {
  date: {
    from: null,
    to: null,
  },
  categories: getCategoriesFromURL(),
  search: "",
  contentTypes: getContentTypesFromURL(),
  loadPastDate: {
    from: null,
    to: null,
  },
};

export default eventFilterModel;
