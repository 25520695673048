import axios, { AxiosError, AxiosResponse } from "axios";
import config from "../config.json";
import { ApiResponseValueKey } from "../types/types";
import { remoteAxios } from "./remoteAxios";

const getTenantUrl = () => {
  if (window.location.hostname === "localhost") {
    return config.publicEndpoints.localhost;
  }
  let tenant = window.location.hostname.split(".")[0];
  if (window.clerkEmbedProps?.tenant) {
    tenant = window.clerkEmbedProps?.tenant;
  }
  // https://civicplus.tpondemand.com/entity/120385-public-portal-embed-site-hosting
  // The embed is hosted as a static file, so process.env will be undefined when accessed.
  // We can add these variables at build time to have these values (see REACT_APP_EMBED) but
  // that is not currently implemented. Currently, all non-localhost embed code will default
  // to the production CivicClerk.WebAPI instance.
  let baseUrl = config.publicEndpoints.baseUrl;
  if (process?.env?.REACT_APP_ENV === "qa") {
    baseUrl = config.publicEndpoints.baseQAUrl;
  } else {
    if (process?.env?.REACT_APP_ENV === "staging") {
      baseUrl = config.publicEndpoints.baseSTAGINGUrl;
    }
  }
  return baseUrl.replace("[TENANT]", tenant);
};

export type ApiError = AxiosError;
export type ApiResponse<T> = AxiosResponse<ApiResponseValueKey<T>>;

export const v1Api = axios.create({
  baseURL: getTenantUrl(),
});

export const setV1ApiToken = (type: string, token: string): void => {
  v1Api.defaults.headers.common["Authorization"] = `${type} ${token}`;
};

export { remoteAxios };
